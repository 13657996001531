import * as React from 'react'
import ReactModal from 'react-modal'
import styled from 'styled-components/macro'
import {X} from 'phosphor-react'

// Define types for props
export type ButtonType =
  | ''
  | 'cancel'
  | 'required'
  | 'info'
  | 'error'
  | 'clear'
  | 'delete'
  | 'unassign'
  | 'remove'
  | 'add'
  | 'update'
  | 'route'
  | 'confirm'

type ColorType = {
  button?: string
  font?: string
  hover?: string
}

type Props = {
  showDialog?: boolean
  disabled?: boolean
  onSave: React.MouseEventHandler<HTMLButtonElement>
  onDismiss?: React.MouseEventHandler<HTMLButtonElement>
  type?: ButtonType
  title?: string
  text?: string
  showDenyButton?: boolean
  colors?: ColorType
}

interface SaveButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  modalType?: string
  color?: string
  hover?: string
  font?: string
}

// Styled components for the modal
const ModalContainer = styled.div`
  border-radius: 20px;
  background: white;
  padding: 0;
`

const Header = styled.div`
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`

const Footer = styled.div`
  padding: 20px;
  margin: 0;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
  justify-content: flex-end;
  display: flex;
`

const Button = styled.button<SaveButtonProps>`
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
  border: none;
  font-weight: 700;
  font-size: 13px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  background-color: ${({modalType, color}) =>
    color
      ? color
      : modalType && standardTypes.includes(modalType)
      ? 'var(--asc-vermilion)'
      : 'var(--secondary)'};
  color: ${({modalType, font}) =>
    font
      ? font
      : modalType && standardTypes.includes(modalType)
      ? 'white'
      : 'var(--primary)'};
  :hover {
    color: ${({modalType, font}) =>
      font
        ? font
        : modalType && standardTypes.includes(modalType)
        ? 'white'
        : 'var(--secondary)'};
    background-color: ${({modalType, hover}) =>
      hover
        ? hover
        : modalType && standardTypes.includes(modalType)
        ? 'var(--asc-vermilion)'
        : 'var(--primary)'};
  }
  :disabled {
    cursor: not-allowed;
    background-color: var(--asc-platinum);
    color: var(--asc-dimgray);
  }
  svg {
    height: 20px;
    width: 20px;
    margin-right: 7px;
  }
`

const CancelButton = styled(Button)`
  background-color: white;
  color: var(--primary);
  :hover {
    background-color: var(--asc-platinum);
  }
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
  h2 {
    font-size: 24px;
    margin-left: 30px;
    margin-right: 30px;
    word-break: break-word;
  }
`

const ModalContent = styled.div`
  padding: 15px 30px;
  display: flex;
  flex-direction: column;
  max-height: 65vh;
  font-size: 18px;
  white-space: pre-line;
`

const standardTypes = [
  'cancel',
  'clear',
  'delete',
  'error',
  'unassign',
  'remove',
]

// Main modal component using react-modal
const AlertModal: React.FC<Props> = ({
  showDialog = false,
  disabled = false,
  onSave,
  onDismiss,
  type = 'confirm',
  title = '',
  text = '',
  showDenyButton = false,
  colors,
}) => {
  return (
    <ReactModal
      isOpen={showDialog}
      onRequestClose={onDismiss}
      ariaHideApp={false}
      contentLabel={`${type}-modal`}
      style={{
        content: {
          borderRadius: '20px',
          padding: '0',
          overflow: 'hidden',
          maxHeight: '98vh',
          inset: 'unset',
          width: '566px',
        },
        overlay: {
          backgroundColor: 'hsla(0, 0%, 0%, 0.33)',
          justifyContent: 'center',
          padding: '10px',
          display: 'flex',
          zIndex: 2,
        },
      }}
    >
      <ModalContainer>
        <Header>
          <Row>
            <h2 role="title">{title}</h2>
          </Row>
        </Header>
        <ModalContent>{text}</ModalContent>
        <Footer>
          {showDenyButton && onDismiss ? (
            <CancelButton onClick={onDismiss} type="button" role="cancelButton">
              <X size={13} />
              <span role="CancelButtonText">
                {type === 'cancel' ? 'NO, KEEP WORKING' : 'CANCEL'}
              </span>
            </CancelButton>
          ) : null}
          <Button
            disabled={disabled}
            onClick={onSave}
            type="button"
            modalType={type}
            color={colors?.button}
            hover={colors?.hover}
            font={colors?.font}
            role="saveButton"
          >
            {type === 'cancel' || type === 'route'
              ? 'YES, CANCEL'
              : type === 'required' || type === 'info' || type === 'error'
              ? `GOT IT`
              : type === 'clear'
              ? 'YES, CLEAR'
              : type === 'delete'
              ? 'YES, DELETE'
              : type === 'unassign'
              ? 'YES, UNASSIGN'
              : type === 'remove'
              ? 'YES, REMOVE'
              : type === 'add'
              ? 'ADD'
              : type === 'update'
              ? 'UPDATE STATUS'
              : 'CONFIRM'}
          </Button>
        </Footer>
      </ModalContainer>
    </ReactModal>
  )
}

export default AlertModal
