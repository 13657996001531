import * as React from 'react'
import {Icon, Checkbox} from 'semantic-ui-react'
import {useQueryClient, useMutation} from '@tanstack/react-query'
import axios from 'axios'
import {useStore} from 'common/useStore'
import {addMilliseconds, startOfDay, getTime} from 'date-fns'
import {DateInput, Spinner} from 'common/components'
import Timepicker from 'common/components/TimePicker'
import {convertToUsersDateFormat} from 'common/helpersDateTime'
import {numberWithCommas} from 'common/helpersNumber'
// import SurfsightMediaViewer from './SurfsightMediaViewer'
import Swal from 'sweetalert2'
import {utcToZonedTime} from 'date-fns-tz'
import SurfsightRecordingTimeline from './SurfsightRecordingTimeline'
import Slider from 'common/components/Slider'
import {
  CameraListItemType,
  CameraRequestStateType,
  HyperLapseContent,
  LoadingCamerasText,
  NoCameraOnLine,
  Options,
  Paragraph,
  RadioGroup,
  SelectContent,
  SliderComponent,
  SmartWitnessContainer,
  StyledMessage,
  StyledModalBody,
  StyledSelect,
  TimeContent,
  TimePickerContainer,
  cameraRequestReducer,
  getInitialState,
  getVideoURL,
  secondsToMinutes,
} from './cameraEventRequestVideoHelper'
import useCamerasOnline, {CameraOnlineType} from './hooks/useCamerasOnline'
import EarliestRecordingDate from './EarliestRecordingDate'
import Modal from 'common/Modal'

const subSamplingOptions = [
  {text: 'Regular 10', value: 10, key: 'Regular 10'},
  {text: 'Fast 30', value: 30, key: 'Fast 30'},
  {text: 'Hyperspeed 60', value: 60, key: 'Hyperspeed 60'},
]

export type RequestedCameraType = {
  idAsset: number
  idDevice: number
  idDevice_Type: number
}
const CameraEventRequestModal = ({
  closeModal,
  requestedCamera,
  resetRequestedCamera,
}: {
  closeModal: () => void
  requestedCamera: RequestedCameraType | null
  resetRequestedCamera?: () => void
}) => {
  const queryClient = useQueryClient()
  const [state, dispatch] = React.useReducer(
    cameraRequestReducer,
    getInitialState(requestedCamera || null),
  )
  const userConfig = useStore(state => state.userConfig)
  const {data: onlineCameras, status: camerasOnlineStatus} = useCamerasOnline()
  const setState = (data: Partial<CameraRequestStateType>) => {
    dispatch({type: 'setState', data})
  }
  const configureSlider = (idAsset: string | number | undefined) => {
    //not showing hyperlapse bydefault
    const currentState = {...state}
    const currentAssetSelected = onlineCameras?.find(
      (asset: CameraListItemType) => asset.value === Number(idAsset),
    )?.asset
    /**
     * smartwitness camera IDDDV model time request limit default is 20 seconds
     */
    // KP1S = 20 seconds
    // CP4 = 120 seconds (2 minutes)
    // CRX = 600 seconds (10 minutes)
    if (currentAssetSelected && currentAssetSelected.DeviceModelIDDV) {
      const {DeviceModelIDDV} = currentAssetSelected
      if (DeviceModelIDDV) {
        if (
          DeviceModelIDDV.includes('CP4') ||
          DeviceModelIDDV.includes('CP2')
        ) {
          currentState.slider.max = 120
          currentState.slider.value = 60
        } else if (DeviceModelIDDV.includes('KP1S')) {
          currentState.slider.max = 20
          currentState.slider.value = 20
        } else if (DeviceModelIDDV.includes('CRX')) {
          currentState.slider.max = 600
          currentState.slider.value = 60
        }
        //show hyperlapse if device modle is not "KP1"
        if (!DeviceModelIDDV.includes('KP1S')) {
          currentState.showHyperlapseOptions = true
        }
      }
    } else {
      currentState.slider = {
        min: 0,
        max: 20,
        value: 15,
      }
      currentState.showHyperlapseOptions = false
    }
    dispatch({
      type: 'setState',
      data: {
        ...currentState,
        selectedAsset: currentAssetSelected,
        idDevice_Type: currentAssetSelected?.idDevice_Type || undefined,
      },
    })
  }
  const enabledHyperlapseMode = () => {
    const newHyperLapseMode = !state.hyperLapse
    const newSlider = {...state.slider}
    if (newHyperLapseMode === true) {
      newSlider.value = 3600
      newSlider.max = 3600
    } else {
      if (state.slider.value > 120) {
        newSlider.value = 120
        newSlider.max = 120
      }
    }

    dispatch({
      type: 'setState',
      data: {...state, slider: newSlider, hyperLapse: !state.hyperLapse},
    })
  }

  React.useEffect(() => {
    if (requestedCamera && onlineCameras) {
      const selectedAsset = onlineCameras.find(
        (camera: {
          value: number
          label: string
          index: number
          idDevice_Type: number
          asset: CameraOnlineType
        }) => camera.value === requestedCamera?.idAsset,
      )
      configureSlider(requestedCamera.idAsset)
      dispatch({
        type: 'setState',
        data: {
          selectedAsset: selectedAsset ? requestedCamera : null,
        },
      })
    }

    //TODO - refactor configureSlider so it doesn't want to be added here
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onlineCameras, requestedCamera])

  const setDate = (name: string, date: Date) => {
    const oldTime =
      getTime(state.customDateInput) -
      getTime(startOfDay(state.customDateInput))

    const newDate = addMilliseconds(date, oldTime)
    dispatch({
      type: 'setState',
      data: {
        customDateInput: newDate,
      },
    })
  }

  const createVideo = useMutation({
    mutationFn: (url: string) => axios.get(url),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['cameraEvents'],
      })
      Swal.fire(
        'Video requested from device',
        'The event has been added to the list but please allow a few minutes for the video to finish rendering.',
        'info',
      ).then(closeModal)
    },
    onError: () => {
      Swal.fire(
        'Error',
        'There was an error connecting to this camera. Please try again later.',
        'error',
      )
      dispatch({type: 'setState', data: {submitting: false}})
    },
  })

  const handleSave = async () => {
    if (state.idDevice_Type === 35) {
      setState({showSurfsight: true})
    } else {
      if (userConfig) {
        dispatch({type: 'setState', data: {submitting: true}})
        const url = getVideoURL(state, closeModal, userConfig)
        if (url) await createVideo.mutateAsync(url)
      }
    }
    resetRequestedCamera && resetRequestedCamera()
  }

  return (
    <Modal
      showDialog={true}
      title={
        <Paragraph>
          {camerasOnlineStatus === 'error' ? (
            <NoCameraOnLine>
              Sorry, there was an error loading the cameras. Please try again
            </NoCameraOnLine>
          ) : camerasOnlineStatus === 'loading' ? (
            <LoadingCamerasText>
              <Spinner type={'partial'} />
            </LoadingCamerasText>
          ) : onlineCameras?.length > 0 ? (
            <Options>
              <StyledSelect
                value={state.selectedAsset?.idAsset || -1}
                onChange={e => {
                  configureSlider(e.currentTarget.value)
                }}
              >
                <option value="-1" disabled>
                  -- Select Camera --
                </option>
                {onlineCameras?.map((opt: CameraListItemType) => (
                  <option key={opt.index} value={opt.value}>
                    {opt.label}
                  </option>
                ))}
              </StyledSelect>
              {state.selectedAsset?.idDevice &&
                state.selectedAsset?.idDevice_Type === 35 && (
                  <EarliestRecordingDate
                    idDevice={state.selectedAsset.idDevice}
                  />
                )}
            </Options>
          ) : (
            <NoCameraOnLine>
              Sorry, there are no cameras online right now.
            </NoCameraOnLine>
          )}
        </Paragraph>
      }
      width={'1050px'}
      renderFooter={state.selectedAsset?.idDevice_Type === 11}
      disabled={!state.selectedAsset}
      onSave={handleSave}
      submitting={state.submitting}
      onDismiss={() => {
        closeModal()
        resetRequestedCamera && resetRequestedCamera()
      }}
      submitButtonText="SUBMIT"
    >
      <StyledModalBody>
        {state.selectedAsset ? (
          state.selectedAsset.idDevice_Type === 35 ? (
            // <SurfsightMediaViewer
            //   showSurfsight={state.showSurfsight}
            //   showCustomDate={state.showCustomDate}
            //   startTime={state.customDateInput}
            //   asset={state.selectedAsset}
            //   setState={setState}
            //   length={state.slider.value}
            // />
            <>
              <SurfsightRecordingTimeline
                idDevice={state.selectedAsset.idDevice}
              />
            </>
          ) : (
            <SmartWitnessContainer>
              {state.showCustomDate && (
                <TimeContent>
                  <DateInput
                    label=""
                    name="customDateInput"
                    disabled={!onlineCameras?.length}
                    maxDate={state.maxDate}
                    setValue={setDate}
                    value={convertToUsersDateFormat(state.customDateInput)}
                    minDate={undefined}
                    calendarposition={undefined}
                    prefix={undefined}
                    errors={undefined}
                    className={undefined}
                  />
                  <TimePickerContainer>
                    <Timepicker
                      startTime={state.customDateInput}
                      setTime={(time: number) => {
                        const oldDate = startOfDay(state.customDateInput)
                        const newTime =
                          getTime(time) - getTime(startOfDay(time))
                        const newDate = addMilliseconds(oldDate, newTime)
                        dispatch({
                          type: 'setState',
                          data: {
                            customDateInput: newDate,
                          },
                        })
                      }}
                      showSeconds={true}
                    />
                  </TimePickerContainer>
                </TimeContent>
              )}

              <RadioGroup>
                <Checkbox
                  label={'Most Recent'}
                  checked={!state.showCustomDate}
                  data-cy={`most-recent-check`}
                  onChange={() =>
                    dispatch({
                      type: 'setState',
                      data: {
                        showCustomDate: false,
                        customDateInput: addMilliseconds(
                          utcToZonedTime(
                            new Date(),
                            userConfig?.TZ_Moment_Name || '',
                          ),
                          -120000,
                        ),
                      },
                    })
                  }
                  type="radio"
                  radio={true}
                />
                <Checkbox
                  label={'Custom Date/Time'}
                  checked={state.showCustomDate}
                  data-cy={`custom-date-time`}
                  onChange={() =>
                    dispatch({
                      type: 'setState',
                      data: {
                        showCustomDate: true,
                        customDateInput: addMilliseconds(
                          utcToZonedTime(
                            new Date(),
                            userConfig?.TZ_Moment_Name || '',
                          ),
                          -120000,
                        ),
                      },
                    })
                  }
                  type="radio"
                  radio={true}
                />
              </RadioGroup>

              <SliderComponent>
                <Slider
                  tickOption={{
                    interval: state.hyperLapse ? 600 : 20,
                    unit: 's',
                  }}
                  start={state.slider.min}
                  end={state.slider.max}
                  initialValue={state.slider.value}
                  onChange={sliderValue => {
                    dispatch({
                      type: 'setState',
                      data: {
                        slider: {...state.slider, value: sliderValue},
                      },
                    })
                  }}
                />
                {/* <ReactSlider
                      className="horizontal-slider"
                      disabled={false}
                      marks={[
                        state.slider?.options?.floor,
                        state.slider?.options?.ceil,
                      ]}
                      renderMark={props => {
                        return (
                          //@ts-expect-error This library doesn't seem to have proper types
                          <RenderMark {...props}>
                            <MarkerText>
                              {numberWithCommas(String(props.key))}
                            </MarkerText>
                          </RenderMark>
                        )
                      }}
                      onChange={sliderValue => {
                        dispatch({
                          type: 'setState',
                          data: {
                            slider: {...state.slider, value: sliderValue},
                          },
                        })
                      }}
                      //@ts-expect-error This library doesn't seem to have proper types
                      renderTrack={props => <RenderTrack {...props} />}
                      value={state.slider?.value}
                      min={state.slider?.options?.floor}
                      max={state.slider?.max}
                      renderThumb={(props, states) => {
                        const val = states.valueNow
                        return (
                          //@ts-expect-error This library doesn't seem to have proper types
                          <RenderThumb {...props}>
                            {numberWithCommas(val)}
                          </RenderThumb>
                        )
                      }}
                    /> */}
              </SliderComponent>

              <Paragraph>
                <b>Request:</b> <Icon name="clock outline" />{' '}
                {numberWithCommas(state.slider?.value)} seconds ({' '}
                {secondsToMinutes(state.slider?.value)} minutes )
              </Paragraph>

              {state.showHyperlapseOptions ? (
                <HyperLapseContent>
                  <Checkbox
                    data-cy="hyperLapse-checkbox"
                    checked={state.hyperLapse}
                    onChange={enabledHyperlapseMode}
                    label="Hyper Lapse Mode"
                  />
                  <SelectContent>
                    <StyledSelect
                      value={state.subSamplingSelected}
                      onChange={e =>
                        dispatch({
                          type: 'setState',
                          data: {
                            subSamplingSelected: parseInt(
                              e.currentTarget.value,
                            ),
                          },
                        })
                      }
                    >
                      {subSamplingOptions.map(opt => (
                        <option key={opt.value} value={opt.value}>
                          {opt.text}
                        </option>
                      ))}
                    </StyledSelect>
                  </SelectContent>
                  <Paragraph>
                    <b>Hyper Lapse:</b> The Hyper Lapse option will request up
                    to 1 hour of video footage with a reduced frame rate. This
                    will allow you to quickly scan through the footage to narrow
                    down a timeline and help you pinpoint incidents.
                  </Paragraph>
                </HyperLapseContent>
              ) : null}

              <StyledMessage warning compact size="small">
                <b>NOTE:</b> The camera must be powered on to request video
                footage. Also, video content is dependent upon camera usage and
                available memory storage within the camera. Use the calendar
                above to select a specific date and time. This request may take
                approximately 1 to 3 minutes to complete.
              </StyledMessage>
            </SmartWitnessContainer>
          )
        ) : (
          <>
            <h2>Request Camera Video</h2>
            <h3>Select a camera above to begin</h3>
          </>
        )}
      </StyledModalBody>
    </Modal>
  )
}
export default CameraEventRequestModal
