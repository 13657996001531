import * as React from 'react'
import styled from 'styled-components/macro'
import {debounce} from 'lodash'
import {QUERIES} from 'common/constants'
import {applyGlobalFilter} from 'grid/gridTableHelpers'
// import {Filters, Column} from 'grid/gridTypes'
// import {FilterAssetsFromListProps} from 'opening/openingHelpers'
import {useGridFilterStore} from './useGridFilterStore'
// import {ColumnDef} from '@tanstack/react-table'
// import {Asset} from 'common/types/opening1Response'

const Wrapper = styled.span`
  margin: 0 10px 0 15px;
  @media ${QUERIES.tabletAndUp} {
    margin-bottom: 0;
    flex: inherit;
    height: 100%;
  }
  max-width: 300px;
`

const Input = styled.input`
  border: 1px solid var(--asc-graphite);
  border-radius: 7px;
  padding: 3px 10px;
  display: flex;
  @media ${QUERIES.tabletAndUp} {
    min-width: 250px;
    max-width: 300px;
  }
  :focus-visible {
    border: 1px solid transparent;
    outline: 1px solid var(--primary) !important;
    box-shadow: 0 0px 5px 0 var(--secondary);
  }
`

type Props = {
  totalAssetCount: number
  // filterAssetsFromList: (props: FilterAssetsFromListProps) => void
  // columns: ColumnDef<Asset, number>[]
}

const GlobalFilter = ({
  totalAssetCount,
}: // filterAssetsFromList,
// columns,
Props) => {
  const globalFilter = useGridFilterStore(state => state.globalFilter)
  const columnFilters = useGridFilterStore(state => state.columnFilters)
  const setGlobalFilter = useGridFilterStore(state => state.setGlobalFilter)
  const onChange = debounce((value: string) => {
    const currentFilters = {
      globalFilter,
      columnFilters,
    }
    applyGlobalFilter({value, filters: currentFilters})
    // filterAssetsFromList({
    //   gridFilters: {
    //     filters: newFilters,
    //     columns: columns,
    //   },
    // })
  }, 100)

  return (
    <Wrapper>
      <Input
        value={globalFilter || ''}
        onChange={e => {
          setGlobalFilter(e.target.value)
          onChange(e.target.value)
        }}
        placeholder={`Search ${totalAssetCount} records...`}
        data-lpignore="true"
        data-testid="assetgrid-search"
      />
    </Wrapper>
  )
}

export default GlobalFilter
