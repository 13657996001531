import * as React from 'react'
import {NavLink} from 'react-router-dom'
import styled from 'styled-components/macro'

type Props = {
  navigateTo?: string
  label: string
  onClick?: () => void
  dataTestId?: string
}

const Container = styled.div`
  width: 100%;
  color: var(--primary);
  :hover {
    background-color: var(--secondary);
    color: black;
  }
`

const StyledNavLink = styled(NavLink)`
  padding: 5px 15px 5px 15px;
  display: flex;
  color: var(--primary);
  :hover {
    background-color: var(--secondary);
    color: black;
  }
  height: 100%;
  width: 100%;
`

const StyledExternalLink = styled.div`
  padding: 5px 15px 5px 15px;
  cursor: pointer;
  display: flex;
  color: var(--primary);
  :hover {
    background-color: var(--secondary);
    color: black;
  }
  height: 100%;
  width: 100%;
`

const FlyoutItem = ({navigateTo, label, onClick, dataTestId}: Props) => {
  return (
    <Container
      data-testid={`${dataTestId}-${label
        .replace(/[^a-zA-Z0-9]/g, '')
        .toLowerCase()}`}
    >
      {navigateTo ? (
        <StyledNavLink to={navigateTo} aria-disabled={!navigateTo}>
          {label}
        </StyledNavLink>
      ) : (
        <StyledExternalLink onClick={onClick}>{label}</StyledExternalLink>
      )}
    </Container>
  )
}
export default FlyoutItem
