import * as React from 'react'
import styled from 'styled-components/macro'
import {
  InputWithLabel,
  InputDropdownOptional,
  MultiSelect,
  StyledLabel,
  Column,
  AlertModal,
  Spinner,
} from 'common/components'
import {SemanticInputType} from 'common/components/InputDropdownOptional'
import useCustomers, {CustomerType} from 'management/hooks/useCustomers'
import useDevices from 'management/hooks/useDevices'
import usePostDeviceTransfer, {
  DeviceTransferItem,
} from './hooks/usePostDeviceTransfer'
import Swal from 'sweetalert2'
import {showToast} from 'common/components/Toastr'
import ResizableTable from 'common/tables/ResizableTable/ResizableTable'
import {ErrorText} from 'maintenance/plans/components/intervalStyles'
import {
  reducer,
  errorCheck,
  onChangeInput,
  onDropdownChange,
  columns,
  returnSelectedCustomer,
  DeviceTransferStateType,
} from './deviceTransferFormHelpers'
import {EditDeviceType} from './hooks/usePutDevice'
import Modal from 'common/Modal'

const StyledModal = styled(Modal)`
  h2 {
    width: 100%;
  }
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 32px;
  gap: 5px;
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 16px;
  justify-content: flex-start;
  > div {
    width: 100%;
    white-space: nowrap;
  }
`

const StyledMultiSelect = styled(MultiSelect<EditDeviceType>)`
  max-width: 600px;
`

const DeviceTransferForm = ({
  selected,
  dismissModal,
}: {
  selected: CustomerType
  dismissModal: () => void
}) => {
  const initialState: DeviceTransferStateType = {
    deviceTransfer: {
      FromidCustomer: selected?.idcustomer,
      ToidCustomer: undefined,
      Order_Num: '',
      deviceTransfers: [],
    },
    selectedDeviceIDs: [],
    errors: {},
    unitPrice: null,
    showConfirmDialog: false,
  }
  const [state, dispatch] = React.useReducer(reducer, initialState)
  const {data: availableDevices} = useDevices(
    state.deviceTransfer?.FromidCustomer,
  )
  const {data: customers} = useCustomers()
  const {mutateAsync: postDeviceTransfer, status: postDeviceStatus} =
    usePostDeviceTransfer()

  const customerOptions = React.useMemo(() => {
    if (customers) {
      return customers?.map((customer: CustomerType) => ({
        text: customer.customer_name || '',
        value: customer.idcustomer as number,
        key: customer.idcustomer as number,
      }))
    } else return []
  }, [customers])

  const saveClicked = () => {
    const errors = errorCheck(state)
    dispatch({type: 'setState', data: {errors}})
    const errorCount = Object.keys(errors).length
    if (errorCount > 0) {
      Swal.fire({
        icon: 'error',
        title: 'We need a little more detail...',
        text: 'Please check that all required information is included and try again.',
      })
      return
    } else {
      dispatch({type: 'setState', data: {showConfirmDialog: true}})
    }
  }

  const submitForm = async () => {
    try {
      await postDeviceTransfer(state.deviceTransfer)
      showToast('Device successfully transferred', 'success')
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Something went wrong! Please check your entries and try again.',
      })
    }

    dismissModal()
  }

  const inputChanged = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    onChangeInput({e, dispatch, state})
  }

  const deviceSelected = (deviceIDs: (string | number)[]) => {
    const devices: DeviceTransferItem[] = []
    availableDevices?.forEach(device => {
      if (deviceIDs.includes(device.idDevice)) {
        devices.push({
          Serial_Number_Displayed: device.Serial_Number_Displayed,
          idDevice: device.idDevice,
          Unit_Price: state.unitPrice || 0,
        })
      }
    })
    const errors = errorCheck(state, 'selectedDeviceIDs', deviceIDs as number[])
    dispatch({
      type: 'setState',
      data: {
        selectedDeviceIDs: deviceIDs as number[],
        deviceTransfer: {...state.deviceTransfer, deviceTransfers: devices},
        errors,
      },
    })
  }

  const dropdownChanged = ({
    name,
    value,
  }: {
    name: string
    value: SemanticInputType
  }) => onDropdownChange({name, value, dispatch, state})

  return (
    <>
      <StyledModal
        title={`${selected?.customer_name} - Device Transfer`}
        onDismiss={dismissModal}
        onSave={saveClicked}
        showDialog={true}
        showClose={true}
        width="90%"
        maxWidth="1200px"
        renderFooter={true}
        submitButtonText={'Save'}
        scrollable={false}
        submitting={postDeviceStatus === 'loading'}
      >
        <Container>
          <Row>
            <InputWithLabel
              label="Order Number"
              name="Order_Num"
              value={state.deviceTransfer.Order_Num || ''}
              onChange={inputChanged}
              required={true}
              errors={state.errors}
            />
            <InputDropdownOptional
              label="From Customer"
              name="FromidCustomer"
              value={state.deviceTransfer.FromidCustomer || ''}
              onChange={dropdownChanged}
              options={customerOptions || []}
              errors={state.errors}
              clearable={false}
              required={true}
            />
            <InputDropdownOptional
              label="To Customer"
              name="ToidCustomer"
              value={state.deviceTransfer.ToidCustomer || ''}
              onChange={dropdownChanged}
              options={customerOptions || []}
              errors={state.errors}
              clearable={false}
              required={true}
            />
            <InputWithLabel
              label="Unit Price"
              name="unitPrice"
              value={state.unitPrice === null ? '' : state.unitPrice}
              onChange={inputChanged}
              required={true}
              errors={state.errors}
              type="number"
              decimals={2}
            />
          </Row>
          <Row>
            {availableDevices && availableDevices.length > 0 ? (
              <Column>
                <Row>
                  <StyledLabel>{`Devices To Transfer (${
                    state.selectedDeviceIDs?.length || 0
                  })`}</StyledLabel>
                  {state.errors.deviceTransfers && (
                    <ErrorText>
                      {state.errors.deviceTransfers.message}
                    </ErrorText>
                  )}
                </Row>
                <StyledMultiSelect
                  list={availableDevices}
                  selectedIDs={state.selectedDeviceIDs}
                  idField="idDevice"
                  labelField={['Serial_Number_Displayed', 'Device_Type']}
                  customLabel="Serial_Number_Displayed -- (Device_Type)"
                  setChecked={deviceSelected}
                  typeLabel="Devices"
                />
              </Column>
            ) : (
              <Spinner type="partial" />
            )}
          </Row>
          <Row>
            <ResizableTable
              data={state.deviceTransfer.deviceTransfers}
              columns={columns}
              status="success"
              height="calc(-490px + 100vh)"
              showHeader={false}
              showSubHeader={false}
            />
          </Row>
        </Container>
      </StyledModal>
      <AlertModal
        showDialog={state.showConfirmDialog}
        onSave={() => {
          dispatch({type: 'setState', data: {showConfirmDialog: false}})
          submitForm()
        }}
        onDismiss={() =>
          dispatch({type: 'setState', data: {showConfirmDialog: false}})
        }
        showDenyButton={true}
        title={`Confirm Device Transfer`}
        text={`Transfer ${
          state.selectedDeviceIDs?.length || 0
        } device(s) to ${returnSelectedCustomer(
          customers,
          state.deviceTransfer.ToidCustomer,
        )}? \r\n \r\n This will also remove the assets from ${returnSelectedCustomer(
          customers,
          state.deviceTransfer.FromidCustomer,
        )} for the selected devices. This action cannot be undone.`}
        type={'info'}
      />
    </>
  )
}
export default DeviceTransferForm
