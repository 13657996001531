import * as React from 'react'
import styled from 'styled-components/macro'
import {Header} from '@tanstack/react-table'
import {useGridFilterStore} from 'grid/useGridFilterStore'
import {Asset} from 'common/types/opening1Response'

const Input = styled.input`
  border: none;
  display: flex;
  font-weight: normal;
  width: 100%;
  padding: 3px;
  :focus-visible {
    outline: 1px solid var(--primary) !important;
    box-shadow: 0 0px 5px 0 var(--secondary);
  }
  ::placeholder {
    color: var(--asc-coolgray);
  }
`

// A typical debounced input react component
const DebouncedInput = ({
  // value: initialValue,
  onChange,
  dataTestId,
  // debounce = 200,
  ...props
}: {
  // value: string | number
  onChange: (value: string | number) => void
  dataTestId?: string
  // debounce?: number
  // column: Column<T, unknown>
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'>) => {
  // const [value, setValue] = React.useState(initialValue)

  // React.useEffect(() => {
  //   setValue(initialValue)
  // }, [initialValue])

  // React.useEffect(() => {
  //   const timeout = setTimeout(() => {
  //     onChange(value)
  //   }, debounce)

  //   return () => clearTimeout(timeout)
  // }, [debounce, onChange, value])

  return (
    <Input
      {...props}
      // value={value}
      onChange={e => {
        // console.log('value changed', e.target.value)
        onChange(e.target.value)
      }}
      //className used to clear on map reset
      className="columnFilter"
      data-testid={`${dataTestId}`}
    />
  )
}

const ColumnFilter = <T,>({
  header,
  canFilter,
  testIdName,
}: {
  header: Header<T, unknown>
  canFilter: boolean
  testIdName?: string
}) => {
  // const columnFilterValue = header.column.getFilterValue()
  const setColumnFilter = useGridFilterStore(state => state.setColumnFilter)
  const updateColumnFilter = (value: string | number) => {
    setColumnFilter({
      name: header.column.id as keyof Asset,
      value: String(value),
    })
  }
  return canFilter ? (
    <DebouncedInput
      onChange={updateColumnFilter}
      placeholder={`Search...`}
      type="text"
      dataTestId={`${testIdName}-header-filter-${header.id.toLowerCase()}`}
      // value={(columnFilterValue ?? '') as string}
      // column={header.column}
    />
  ) : null
}

export default ColumnFilter
