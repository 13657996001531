import React, {useState, useCallback, useRef} from 'react'
import {useCombobox} from 'downshift'
import {useVirtual} from 'react-virtual'
import styled from 'styled-components/macro'
import InputSpinner from './InputSpinner'
import {StyledLabel} from 'common/components'
import {RequiredAsterisk} from './InputWithLabel'

const LINE_HEIGHT = 33

const Wrapper = styled.div`
  position: relative;
  width: 100%;
`

const StyledInput = styled.input`
  position: relative;
  border: 1px solid var(--asc-coolgray);
  border-radius: 5px;
  padding: 7.5px;
  padding-right: 30px;
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  min-width: 100%;
  position: relative;
  outline: none;
  color: var(--asc-black);
  font-weight: 400;
  ::placeholder {
    color: ${p =>
      p.placeholderTextShowing ? 'var(--asc-black)' : 'var(--asc-graphite)'};
    opacity: 1; /* Firefox */
  }
`

const Icon = styled.i`
  cursor: pointer;
  position: absolute;
  width: auto;
  height: auto;
  line-height: 1.21428571em;
  bottom: 10px;
  right: 1.1em;
  z-index: 3;
  margin: -0.78571429em;
  padding: 0.91666667em;
  opacity: 0.8;
  transition: opacity 0.1s ease;
  float: right;
  backface-visibility: hidden;
  font-weight: 400;
  font-style: normal;
  text-align: center;
  font-family: Dropdown; //semantic icon
  font-size: 0.85714286em;
  ::before {
    content: '\f0d7';
  }
`

const StyledCombobox = styled.div`
  width: 100%;
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  outline: none;
  border: none;
  border-radius: 5px;
  box-shadow: ${({open}) => (open ? '0px 0px 6px 1px var(--secondary)' : null)};
`

const DropDownContainer = styled.ul`
  max-height: 300px;
  overflow-y: auto;
  background-color: #fff;
  list-style: none;
  position: absolute;
  /* margin-top: 9px; */
  top: ${({label}) => (label ? '64px' : '45px')};
  left: 0;
  width: 100%;
  font-size: 1rem;
  z-index: 6;
  border: ${p => (p.isopen ? '1px' : '0')} solid rgba(34, 36, 38, 0.15);
  border-radius: 2px;
  li {
    white-space: nowrap;
    overflow: hidden;
    padding: 0.8rem;
    text-align: left;
    display: flex;
    align-items: center;
    cursor: pointer;
    :hover {
      background-color: var(--secondary);
    }
    img {
      margin-right: 10px;
    }
  }
`

const Autocomplete = ({
  list,
  label,
  onChange,
  selectedID,
  placeholder,
  loading,
  disabled,
  required,
  defaultOpen = false,
  showIcon = false,
}) => {
  const [inputValue, setInputValue] = useState('')
  const [placeholderText, setPlaceholderText] = useState('')
  const [items, setItems] = React.useState([])
  const listRef = useRef()

  React.useEffect(() => {
    if ((selectedID === null || selectedID === 0) && placeholderText !== '') {
      setInputValue('')
      setPlaceholderText('')
    }
  }, [selectedID, placeholderText])
  React.useEffect(() => {
    function getItems(search) {
      let newList
      if (selectedID) {
        newList = []
        //if preselected item is found, move it to the top
        const preSelectedItem = list.find(
          item => String(item.id) === String(selectedID),
        )
        if (preSelectedItem) {
          setPlaceholderText(preSelectedItem.name)
          newList.push(preSelectedItem)
          list.forEach(item => {
            if (String(item.id) !== String(selectedID)) {
              newList.push(item)
            }
          })
        } else {
          newList = [...list]
        }
      } else {
        newList = [...list]
      }
      return newList.filter(n =>
        n?.name?.toLowerCase().includes(search?.toLowerCase()),
      )
    }
    const newItems = getItems(inputValue)
    setItems(newItems)
  }, [inputValue, list, selectedID])
  // const initialItem = list.find(
  //   n => n?.id?.toString() === selectedID?.toString(),
  // )?.name

  const rowVirtualizer = useVirtual({
    size: items.length,
    parentRef: listRef,
    estimateSize: useCallback(() => LINE_HEIGHT, []),
    overscan: 2,
  })
  const inputRef = useRef()

  const {
    getInputProps,
    getItemProps,
    getLabelProps,
    getMenuProps,
    getToggleButtonProps,
    openMenu,
    getComboboxProps,
    isOpen,
  } = useCombobox({
    items,
    inputValue,
    defaultIsOpen: defaultOpen,
    itemToString: item => item?.name || '',
    onInputValueChange: ({inputValue: newValue}) => {
      setInputValue(newValue)
    },
    onSelectedItemChange: ({selectedItem}) => {
      setInputValue(selectedItem?.name)
      setPlaceholderText(selectedItem?.name)
      onChange(selectedItem)
      inputRef.current.blur()
      // selectedRef.current = highlightedIndex
    },
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    scrollIntoView: () => {},
    // onHighlightedIndexChange: ({highlightedIndex, type}) => {
    //   if (type !== useCombobox.stateChangeTypes.MenuMouseLeave) {
    //     rowVirtualizer.scrollToIndex(highlightedIndex)
    //   }
    // },
  })

  // const handleScroll = e => {
  //   // rowVirtualizer.scrollToIndex(
  //   //   selectedRef.current + DROPDOWN_CONTAINER_OFFSET,
  //   // )
  //   console.log(e)
  //   inputRef.current.blur()
  //   setHighlightedIndex(e.current)
  // }

  // //on page load/refresh, ensures asset is displayed in box
  // React.useEffect(() => {
  //   const preSelectedItem = list.find((item, index) => {
  //     const found = String(item.id) === String(selectedID)
  //     if (found) {
  //       selectedRef.current = index
  //       return true
  //     }
  //     return false
  //   })
  //   if (preSelectedItem?.name) {
  //     if (placeholderText !== preSelectedItem.name) {
  //       setPlaceholderText(preSelectedItem.name)
  //     }
  //   } else {
  //     if (placeholderText !== placeholder) {
  //       setInputValue('')
  //       setPlaceholderText(placeholder)
  //     }
  //   }
  // }, [list, onChange, placeholder, placeholderText, selectedID])

  return (
    <Wrapper>
      {label && (
        <StyledLabel {...getLabelProps()}>
          {label}
          {required && <RequiredAsterisk />}
        </StyledLabel>
      )}
      <StyledCombobox
        // initialSelectedItem={initialItem}
        {...getComboboxProps()}
        open={isOpen}
        // onClick={handleScroll}
      >
        <StyledInput
          {...getInputProps({
            ref: inputRef,
            type: 'text',
            onFocus: () => {
              if (!isOpen) {
                if (inputValue) {
                  // setPlaceholderText(inputValue)
                  setInputValue('')
                }
                openMenu()
              }
            },
            onBlur: () => {
              if (inputValue && inputValue !== placeholderText) {
                //something was entered into input but no selection made
                setTimeout(() => {
                  setInputValue('')
                }, 300)
              }
            },
          })}
          disabled={disabled}
          data-cy="autocomplete"
          placeholderTextShowing={!isOpen && !inputValue}
          placeholder={placeholderText || placeholder}
          data-lpignore="true"
        />
        {loading === 'true' ? (
          <InputSpinner></InputSpinner>
        ) : (
          showIcon && (
            <Icon
              label={label}
              data-cy="autocomplete-icon"
              className="autocomplete-icon"
              {...getToggleButtonProps()}
            />
          )
        )}
      </StyledCombobox>

      <DropDownContainer
        {...getMenuProps({
          ref: listRef,
        })}
        isopen={isOpen}
        label={label}
      >
        {isOpen && (
          <>
            <li key="total-size" style={{height: rowVirtualizer.totalSize}} />
            {rowVirtualizer.virtualItems.map(virtualRow => (
              <li
                title={items[virtualRow.index].name}
                key={
                  items[virtualRow.index].uniqueKey ||
                  items[virtualRow.index].name
                }
                data-cy={'row-' + virtualRow.index}
                {...getItemProps({
                  index: virtualRow.index,
                  item: items[virtualRow.index],
                  style: {
                    color: 'var(--primary)',
                    fontWeight:
                      selectedID && virtualRow.index === 0 ? 'bold' : 'normal',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: virtualRow.size,
                    transform: `translateY(${virtualRow.start}px)`,
                  },
                })}
              >
                {/* <img src="dd" /> */}
                {items[virtualRow.index].name}
              </li>
            ))}
          </>
        )}
      </DropDownContainer>
    </Wrapper>
  )
}

export default React.memo(Autocomplete)
