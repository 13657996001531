import * as React from 'react'
import styled from 'styled-components/macro'
const StyledSelect = styled.select`
  font: inherit;
  border: 1px solid var(--primary);
  border-radius: 4px;
  appearance: none;
  line-height: 1.5em;
  padding: 0.5em 3.5em 0.5em 1em;
  width: 100%;
  background-image: linear-gradient(45deg, transparent 50%, gray 50%),
    linear-gradient(135deg, gray 50%, transparent 50%);
  background-position: calc(100% - 20px) calc(1em + 2px),
    calc(100% - 15px) calc(1em + 2px);
  background-size: 5px 5px, 5px 5px;
  background-repeat: no-repeat;
  outline: none;
  :focus {
    outline: none;
    box-shadow: 0px 0px 6px 1px var(--secondary);
  }
  :disabled {
    background-color: var(--grey-300);
  }
`

const Select = ({
  children,
  ...rest
}: React.InputHTMLAttributes<HTMLSelectElement>) => {
  return <StyledSelect {...rest}>{children}</StyledSelect>
}
export default Select
