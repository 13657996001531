import styled from 'styled-components/macro'
import {ModalHeader, Button} from 'semantic-ui-react'
import {Row as CommonRow} from './components'
// Styled components for modal
export const ModalContainer = styled.div<{
  maxWidth?: string
  width: string
  height: string
}>`
  max-width: ${props => props.maxWidth || '100vw'};
  max-height: 98vh;
  position: relative;
  display: flex;
  flex-direction: column;
  background: white;
  border-radius: 20px;
  padding: 0;
  width: ${props => props.width};
  height: ${props => props.height};
  z-index: 2;
`

export const Header = styled.div<{tabs?: any}>`
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  padding: ${({tabs}) => (tabs ? '20px 20px 0 20px' : '20px')};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--asc-coolgray);
`

export const Footer = styled(ModalHeader)`
  background-color: white;
  padding: 20px 20px;
  margin: 0;
  border-top: 1px solid var(--asc-coolgray);
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
  justify-content: flex-end;
  display: flex;
  width: 100%;
`

export const CloseArea = styled.div`
  padding: 10px;
  cursor: pointer;
`

export const SaveButton = styled(Button)`
  &&& {
    white-space: nowrap;
    background: var(--secondary);
    color: var(--primary);
    text-transform: uppercase;
    :hover {
      background: var(--primary);
      color: var(--secondary);
    }
    :active {
      background: var(--secondary) !important;
      color: var(--primary) !important;
    }
    :focus {
      background: var(--secondary) !important;
      color: var(--primary) !important;
    }
    :disabled {
      cursor: not-allowed;
      background-color: var(--asc-platinum);
      color: var(--asc-dimgray);
    }
  }
`
export const CancelButton = styled(Button)`
  display: flex !important;
  flex-direction: row;
  gap: 5px;
  align-items: center;
  background-color: transparent !important;
  color: var(--primary) !important;
  :hover {
    background-color: var(--asc-platinum) !important;
  }
`
export const SubTitle = styled.span`
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  margin-top: 10px;
  color: var(--asc-black);
  width: 100%;
`
export const Row = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`
export const ModalContent = styled.div<{
  scrollable: boolean
  renderFooter: boolean
}>`
  display: flex;
  flex-direction: column;
  overflow: ${({scrollable}: {scrollable: boolean; renderFooter: boolean}) =>
    scrollable ? 'hidden' : 'initial'};
  flex-grow: 1;
  input,
  textarea {
    //border: 1px solid var(--asc-graphite) !important;
    :focus {
      border: 1px solid var(--primary) !important;
      box-sizing: border-box;
      box-shadow: 0px 0px 6px 1px var(--secondary) !important;
      outline: 0;
    }
  }
  border-radius: ${(props: {renderFooter: boolean}) =>
    props.renderFooter ? '0' : '0 0 20px 20px'};
`
export const RequiredText = styled.span`
  margin-top: -5px;
  font-size: 14px;
  :before {
    content: '*';
    font-style: italic;
    font-size: 0.8rem;
    color: var(--error-text);
    padding-right: 3px;
  }
`
export const TabButton = styled.button`
  z-index: 1;
  display: flex;
  justify-content: space-around;
  cursor: ${({disabled}) => (disabled ? 'default' : 'pointer')};
  align-items: center;
  height: 100%;
  font-size: 13px;
  padding: 0 0 10px 0;
  font-weight: ${({active}: {active: boolean; text: string}) =>
    active ? '700' : '400'};
  box-shadow: ${({active}) =>
    active ? 'inset 0 -5px var(--secondary)' : 'none'};
  :hover {
    box-shadow: inset 0 -5px var(--secondary);
  }
  border: 0;
  background: transparent;
  :after {
    content: ${({text}) => (text ? `"${text}"` : '')};
  }
`
export const TabButtonContainer = styled.div`
  padding-top: 20px;
  display: flex;
  flex-direction: row;
  gap: 20px;
`

export const FooterRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`

export const ScrollableBody = styled.div`
  overflow: ${({scrollable}: {scrollable: boolean}) =>
    scrollable ? 'auto' : 'initial'};
  flex: 1;
  display: flex;
  flex-direction: column;
`

export const ErrorMessage = styled.div`
  display: flex;
  flex-direction: row;
  background: var(--asc-lavender);
  border: 1px solid var(--asc-vermilion);
  text-align: center;
  align-items: center;
  padding: 10px;
  border-radius: 5px;
  color: var(--asc-vermilion);
  svg {
    margin-right: 5px;
    margin-top: 2px;
  }
`

export const CloseContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const ModalHeaderTitle = styled.h2`
  margin-bottom: 0;
`
export const ButtonRow = styled(CommonRow)`
  gap: 10px;
`
